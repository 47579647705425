import React, { useState, useEffect } from 'react';
import './css/style.css';
import './css/header.css';
import './css/footer.css';
import './css/custom.css';
import './config/bootstrap.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'


/** pages */
import Home from './pages/index';
import NotFound from './pages/not_found';
import Policy from './pages/policy';


import HeaderBlock from './components/HeaderBlock';
import FooterBlock from './components/FooterBlock';


import { Helmet } from "react-helmet";
import { settings } from './config/settings';
import { ParallaxProvider } from 'react-scroll-parallax';

import { LoginManager } from "./core/login-manager";



function App() {

    library.add(fas)

    useEffect(() => {
        async function init() {
            var response = await LoginManager.checkLogin();
            console.log("checkLogin", response);
        }

        init();
        detectSidChange();
    }, []);

    async function detectSidChange() {
        var sessionId = LoginManager.registerChangeSid(async function () {
            var response = await LoginManager.checkLogin();
            console.log("CheckLogin after sid changed", response);
        });
        console.log("--- SESSION ID " + sessionId + " ---");
    }


    useEffect(() => {
        async function init() {
            
        }

        init()  
    }, []);
    


    return (
        <ParallaxProvider>
            <Router basename={'/'}>
                <div id="app-container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{settings.title}</title>
                        <meta name="description" content={settings.description} />
                    </Helmet>
                    <HeaderBlock />
                    <Routes>
                        <Route exact path='/' element={<Home />} /> {/* default page */}
                        <Route path="*" element={<NotFound />} /> {/* 404 page */}
                        <Route path='/policy' element={<Policy />} />
                    </Routes>
                    <FooterBlock />
                </div>
            </Router>
        </ParallaxProvider>
    );
   
    
}

export default App;