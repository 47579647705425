import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import logo from '../../assets/health51.png';



export default function HeaderBlock() {

    const [isScrolled, setIsScrolled] = useState(false);
    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
    }, [location]);


    useLayoutEffect(() => {
        //document.documentElement.scrollTo(0, 0);
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [location.pathname]);


    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            // Set the threshold for when to add the class (e.g., 50px)
            if (scrollTop > 70) {
                setIsScrolled(true);
                document.body.classList.add("scrolled");
            } else {
                setIsScrolled(false);
                document.body.classList.remove("scrolled");
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <>
            <header className="" data-scroll={isScrolled}>
                <Container fluid='lg' style={{ position: 'relative' }} className='text-center'>
                    <Link to="/">
                        <Image id="header-logo" src={logo} alt='health51' height={120} />
                    </Link>
                </Container>
            </header>
        </>
    );
};