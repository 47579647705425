import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';


export default function FooterBlock() {


    return (
        <Container fluid className='p-3 text-secondary' id="footer">
            <Container fluid='md'>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <div className='footer-widget py-3'>
                            <h4>Health 51 - Conegliano</h4>
                            <p>
                                Via Venezia, 57/A,<br />
                                San Vendemiano (TV), 31020<br />
                                Italia
                            </p>
                            <h4>Contatti</h4>
                            <p>
                                <i>Info</i>: <b><a className="link-secondary" href="mailto:centrohealth51@gmail.com">centrohealth51@gmail.com</a></b><br />
                                <i>Tel</i>: <b><a className="link-secondary" href="tel:+393755035466">3755035466</a></b><br />
                                Lasciaci un messaggio, ti risponderemo il prima possibile.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={2} lg={2}></Col>
                    <Col xs={12} md={4} lg={4}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2777.2798361984787!2d12.317439976863222!3d45.88571607108383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477915721c7e1a5d%3A0x767b5fac9a6a9444!2sHealth51!5e0!3m2!1sit!2sit!4v1732880115052!5m2!1sit!2sit" width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Col>

                </Row>
            </Container>
            <Container fluid='md' className='mt-5 text-center'>
                <p className='text-secondary'>© 2024 Health51. | P.iva 05511640269</p>
            </Container>
        </Container>
    );
};