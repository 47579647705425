import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';


export default function CookiePolicy() {

    return (
        <>
            <Helmet>
                <title>Cookie Policy - Gate 51</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 bg-palette-dark' style={{ paddingTop: 150 }}>
                <Container fluid='lg' className='text-white text-start'>
                    <h1>Policy</h1>

                </Container>
            </Container>
        </>
    );
};