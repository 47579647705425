import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Image, Button } from 'react-bootstrap';
import notfound from '../assets/404.jpg';
import { Link } from 'react-router-dom';


export default function NotFound() {

    return (
        <>
            <Helmet>
                <title>Page Not Found - Gate 51</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 bg-palette-dark' style={{ paddingTop: 150, minHeight: '100vh' }}>
                <Container fluid='lg' className='text-white text-center'>
                    <div style={{ maxWidth: 600, margin: 'auto' }} >
                        <h1>Errore 404</h1>
                        <h2 className='lead'>La pagina che stai cercando non esiste.</h2>
                        <p>
                            <Link to='/'>
                                <Button size='lg' variant='warning' className='mt-3 w-100'>Torna alla home</Button>
                            </Link>
                        </p>
                        
                        <Image src={notfound} className="mt-5" width={'100%'} />
                    </div>
                </Container>
            </Container>
        </>
    );
};